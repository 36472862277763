import GamesSelectToggle from '@components/atoms/Session/GamesSelectToggle'
import Dropdown from '@components/atoms/Dropdown/Dropdown'
import { BACKGROUND_COLORS, COLORS, COLORS_DONUT_GRAPH } from '@constants'

export const USER_FIELDS = [
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'phone',
    label: 'Phone',
  },
  {
    key: 'location',
    label: 'Location',
  },
  {
    key: 'age',
    label: 'Age',
  },
  {
    key: 'gender',
    label: 'Gender',
  },
  {
    key: 'status',
    label: 'Player Status',
  },
  {
    key: 'registered',
    label: 'Registered',
  },
  {
    key: 'lastLogin',
    label: 'Last login date',
  },
  {
    key: 'prevSupport',
    label: 'Previous Support',
  },
]

export const QUICK_ACTIONS = [
  {
    label: 'Manage player wallet',
    style: 'regular',
    hasIcon: true,
    id: '',
    visible: true,
    name: 'wallet',
    callback: () => {},
  },
  {
    label: 'Activate account',
    style: 'success',
    hasIcon: false,
    id: '',
    visible: false,
    name: 'active',
    callback: () => {},
  },
  {
    label: 'Make account provisional',
    style: 'warning',
    hasIcon: false,
    id: '',
    visible: false,
    name: 'provisional',
    callback: () => {},
  },
  {
    label: 'Self-exclude player',
    style: 'warning',
    hasIcon: false,
    id: '',
    visible: false,
    name: 'self-excluded',
    callback: () => {},
  },
  {
    label: 'Ban player',
    style: 'light-danger',
    hasIcon: false,
    id: '',
    visible: false,
    name: 'banned',
    callback: () => {},
  },
  {
    label: 'Freeze account',
    style: 'light-danger',
    hasIcon: false,
    id: '',
    visible: false,
    name: 'frozen',
    callback: () => {},
  },
  {
    label: 'Close account',
    style: 'danger',
    hasIcon: false,
    id: '',
    visible: false,
    name: 'closed',
    callback: () => {},
  },
]

export const QUICK_ACTIONS_MAP = Object.fromEntries(
  QUICK_ACTIONS?.map((item) => [item.name, item]),
)

export const GRAPH_TYPES = {
  RADAR: 'radar',
  BAR: 'bar',
}

export const GRAPH_COMPONENTS = {
  [GRAPH_TYPES.BAR]: {
    Component: Dropdown,
    props: {
      title: 'Graph values',
      selected: 'Monthly',
      onSelect: (selected) => console.log(selected),
      options: ['Monthly', 'Weekly', 'Daily'],
    },
  },
  [GRAPH_TYPES.RADAR]: {
    Component: GamesSelectToggle,
    props: {},
  },
}
export const CHANGE_PLAYER_M = 'CHANGE PLAYER ACCOUNT TO'
export const WARNING_PLAYER_STATUS = `Warning! This cannot be undone and the account will be
permanently closed!`
export const CLOSE_MESSAGE = 'CLOSE PLAYER ACCOUNT'
export const REASON = 'Required reason.'

// default dropdown options for player view dropdown
export const NAVIGATION_MENU_OPTIONS = [
  {
    optionName: 'Player Details',
    optionKey: 'PLAYER_DETAILS',
    category: 'Player Details',
    nested: [
      {
        optionName: 'Personal Details',
        optionKey: 'PLAYER_DETAILS',
        category: 'Player Details',
      },
      {
        optionName: 'Limits',
        optionKey: 'WITHDRAWAL_DEPOSIT_LIMITS',
        category: 'Player Details',
      },
    ],
  },
  {
    optionName: 'Marketing Preferences',
    optionKey: 'MARKETING',
    category: 'Marketing Preferences',
    nested: [
      {
        optionName: 'Marketing Preferences',
        optionKey: 'MARKETING',
        category: 'Marketing Preferences',
      },
    ],
  },
  {
    optionName: 'Wallets & Bonuses',
    optionKey: 'WALLETS_BONUSES',
    category: 'Wallets & Bonuses',
    nested: [
      {
        optionName: 'Wallets',
        optionKey: 'WALLETS_BONUSES',
        category: 'Wallets & Bonuses',
      },
      {
        optionName: 'Transaction History',
        optionKey: 'TRANSACTION_HISTORY',
        category: 'Wallets & Bonuses',
      },
      {
        optionName: 'Bonus Assignments',
        optionKey: 'BONUSES_ASSIGNMENTS',
        category: 'Wallets & Bonuses',
      },
    ],
  },
  {
    optionName: 'Player Statistics',
    optionKey: 'STATISTICS',
    category: 'Statistics',
    nested: [
      {
        optionName: 'Deposits',
        optionKey: 'STATISTICS',
        category: 'Statistics',
      },
      {
        optionName: 'Gameplay',
        optionKey: 'GRAPH',
        category: 'Statistics',
      },
    ],
  },
  {
    optionName: 'Player Notes',
    optionKey: 'PLAYER_NOTES',
    category: 'Player Notes',
    nested: [
      {
        optionName: 'Player Notes',
        optionKey: 'PLAYER_NOTES',
        category: 'Player Notes',
      },
    ],
  },
  {
    optionName: 'Audit History',
    optionKey: 'AUDIT_HISTORY',
    category: 'Audit History',
    nested: [
      {
        optionName: 'Audit History',
        optionKey: 'AUDIT_HISTORY',
        category: 'Audit History',
      },
    ],
  },
]

// wallet transactions constants
export const BALANCE_TITLE = 'Manual balance adjustment'
export const BONUS_TITLE = 'Add To Bonus'
export const BALANCE_SUBTITLE =
  'Amount (player’s balance can be increased or decreased)'
export const BONUS_SUBTITLE =
  'Bonus (player’s balance can be increased or decreased)'
export const EXCEED_BALANCE = 'Withdraw exceeds balance'
export const BONUS = 'Bonus:'
export const CASH = 'Cash:'
export const MIKADO_BANK = 'Mikado Bank'
export const CASH_BALANCE = 'cash'
export const BONUS_BALANCE = 'bonus'
export const BONUS_FORM = 'bonus'
export const CASH_FORM = 'amount'
export const PROVISIONAL_STATE = 'provisional'
export const ACTIVE_STATE = 'active'
export const BITCOIN_WALLET = '₿'
export const BITCOIN_PLACEHOLDER = '0.00000001'
export const COINS_PLACEHOLDER = '0.01'
export const MANUAL_ADJUSTMENT_PAYMENT = 'manual'
export const MANUAL_ADJUSTMENT_ID = '1'

// Live chat
export const LIVE_CHAT_HEIGHT = 106.5

// Deposit limits constants
export const DEPOSIT = 'deposit'
export const DEPOSIT_VALIDATION =
  'None of the limits can be equal to zero. The daily limit must be lower than the weekly limit, and the weekly limit must be lower than the monthly limit.'
export const DEPOSIT_VALIDATION_EXCEED = 'The max amount allowed is 99999.99.'
export const MAX_DEPOSIT_LIMIT = 99999.99
export const DAILY_LIMIT = 'Max Daily'
export const WEEKLY_LIMIT = 'Max Weekly'
export const MONTHLY_LIMIT = 'Max Monthly'
export const WALLET_LABEL = 'Wallet'
export const CURRENT = '*Current: '
export const DAYLY = 'daily'
export const WEEK = 'week'
export const MONTH = 'month'
export const DEPOSIT_LIMIT = 'Deposit Limits'
export const INITIAL_DEPOSITS = {
  daily: 0,
  week: 0,
  month: 0,
  id: '',
}

export const DEPOSIT_IDS = [DAYLY, WEEK, MONTH]
export const DEPOSIT_LABELS = [DAILY_LIMIT, WEEKLY_LIMIT, MONTHLY_LIMIT]
// Marketing preferences options
export const MARKETING = 'Marketing'
export const MARKETING_PREFERENCES = `${MARKETING} Preferences`
export const SMS_M = 'sms'
export const BONUS_M = 'bonus'
export const EMAIL_M = 'email'
export const MARKETING_IDS = [SMS_M, EMAIL_M, BONUS_M]

export const SMS_LABEL = `SMS ${MARKETING}`
export const EMAIL_LABEL = `Email ${MARKETING}`
export const BONUS_LABEL = 'Bonuses'
export const MARKETING_LABELS = [SMS_LABEL, EMAIL_LABEL, BONUS_LABEL]

// Withdrawal limits constants
export const WITHDRAWAL = 'withdraw'
export const WITHDRAW_LIMIT = 'Withdrawal Limits'
// Transaction history constants

export const TRANSACTION_ERROR =
  'Manual adjustment has failed. A connection error has occurred.'

export const TRANSACTION_FIELDS = [
  {
    key: 'date_time_UTC',
    label: 'Date Time UTC',
  },
  {
    key: 'reference',
    label: 'Reference',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'amount',
    label: 'Cash',
  },
  {
    key: 'bonus',
    label: 'Bonus',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'balance',
    label: 'Balance',
  },
]
export const FIELDS_BLOCKCHAIN = [
  {
    key: 'blockchain',
    label: 'Blockchain Link',
  },
]
export const CRYPTO_CURRENCIES = ['BTC']

// constants
export const URL_BLOCKCHAIN =
  'https://blockchair.com/bitcoin/transaction/bd74efbb000dc35407893185191333ffaa5e45461a4fc40f4ce2412588eb727f'

// Transaction filters constants
export const TRANSACTION_TYPE = 'Transaction Type'
export const FROM_DATE = 'From Date'
export const TO_DATE = 'To Date'
export const SEARCH = 'Search'

export const WALLET_FILTER = 'wallet'
export const TRANSACTION_FILTER = 'transaction type'
export const INIT_DATE_FILTER = 'init date'
export const END_DATE_FILTER = 'end date'
export const INIT_ID = 'date_init'
export const END_ID = 'date_finish'
export const TYPE_ID = 'Wallet'
export const WALLET_ID = 'Wallet'
export const TRANSACTION_HISTORY_TITLE = 'Transaction History'

// Audit History constants
export const historyFields = ['date', 'operator', 'field', 'before', 'after']
export const BONUSES_ASSIGNMENTS_FIELDS = [
  {
    key: 'campaignId',
    label: 'Campaigns',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
  {
    key: 'maxAmountRedeem',
    label: 'Max Amount Redeem',
  },
  {
    key: 'percentageBet',
    label: 'Percentage Bet',
  },
  {
    key: 'rolloverTarget',
    label: 'Rollover Target',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'expirationDate',
    label: 'Expiration Date',
  },
]

export const NUMBER_OF_ROWS = 30
// Graph constants
export const GRAPH_OPTIONS = [
  {
    name: 'Last 7 days',
    key: 'LAST_7',
  },
  {
    name: 'Last 14 days',
    key: 'LAST_14',
  },
  {
    name: 'Last 30 days',
    key: 'LAST_30',
  },
  {
    name: 'Last 90 days',
    key: 'LAST_90',
  },
  {
    name: 'Last 365 days',
    key: 'LAST_365',
  },
]
export const GRAPH_DAYS = [7, 14, 30, 90, 365]
export const LIMIT_GAMES = 6

// Player Details constants

// Deposit graph constants
export const DEPOSIT_LABEL = 'Total deposits: €'
export const RADART_LABEL = 'Played: '
export const BAR_GRAPH_COLORS = [
  { stop: 0.4, label: '#F2B205' },
  { stop: 0.25, label: '#F2B705' },
  { stop: 0.25, label: '#f1d11c' },
  { stop: 0, label: '#f1d17c' },
]

function getTotalDepositsLabels(label) {
  return DEPOSIT_LABEL + (Number(label)?.toFixed(2) ?? 0)
}

export const DEPOSIT_GRAPH_OPTIONS = {
  responsive: true,
  legend: {
    display: false,
    labels: {
      fontColor: '#191957',
    },
  },
  aspectRatio: 1.5,
  maintainAspectRatio: true,
  tooltips: {
    enabled: true,
    callbacks: {
      label(context) {
        const label = context?.value || ''
        return getTotalDepositsLabels(label)
      },
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontColor: '#353589',
          callback(value) {
            return `€${value.toFixed(2)}`
          },
        },
        gridLines: {
          color: 'lightgrey',
          lineWidth: 1,
          zeroLineWidth: 2,
          zeroLineColor: 'red',
          drawBorder: false,
          drawTicks: false,
          borderDash: [5, 5],
          borderDashOffset: 5,
          alpha: 0.5,
          drawOnChartArea: false, // set to false to display over the bars
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontColor: '#353589',
        },
        gridLines: {
          color: '#353589',
          lineWidth: 1,
          zeroLineWidth: 2,
          drawBorder: false,
          drawTicks: false,
          alpha: 0.5,
          offsetGridLines: false,
        },
      },
    ],
  },
}

export const BAR_DATA = (totalDeposits) => [
  {
    label: 'Total deposits',
    backgroundColor: (context) => {
      const { chart } = context
      const { ctx, chartArea } = chart
      const gradient = ctx.createConicGradient(35.075, 250, 250)
      if (!chartArea) {
        return null
      }
      BAR_GRAPH_COLORS.map(({ label, stop }) =>
        gradient.addColorStop(stop, label),
      )

      return gradient
    },
    barThickness: 6,
    borderColor: 'none',
    borderWidth: 2,
    hoverBorderColor: 'none',
    data: totalDeposits,
  },
]

export const DEPOSIT_GRAPH_SELECTIONS = [
  {
    name: 'Last 12 months',
    key: 12,
  },
  {
    name: 'Last 6 months',
    key: 6,
  },
  {
    name: 'Last 7 days',
    key: 7,
  },
]

export const MONTH_DEPOSIT_TYPE = 'month'
export const DAY_DEPOSIT_TYPE = 'day'
export const MONTH_INITIAL = 12
export const GRAPH_STYLES_SIZES = (isSmScreen) =>
  !isSmScreen
    ? { height: 500, width: 500, margin: 'auto' }
    : { height: 350, width: 350, margin: 'auto' }

// Radar chart

export const dataSet = (values, valuesRef) => [
  {
    label: 'Played',
    fill: true,
    data: values,
    backgroundColor: (context) => {
      const { ctx } = context.chart
      const gradient = ctx.createConicGradient(35.075, 250, 250)

      COLORS.map(({ label, stop }) => gradient.addColorStop(stop, label))

      return gradient
    },
    pointBorderColor: 'yellow',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(179,181,198,1)',
    borderColor: valuesRef[0] === 10 ? 'none' : 'rgba(0, 0, 0, 0.1)',
  },
  {
    label: '',
    fill: true,
    data: valuesRef,
    backgroundColor: (context) => {
      const { ctx } = context.chart
      const gradient = ctx.createConicGradient(35.075, 250, 250)

      BACKGROUND_COLORS.map(({ label, stop }) =>
        gradient.addColorStop(stop, label),
      )
      return gradient
    },
    pointBorderColor: 'yellow',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(179,181,198,1)',
    borderColor: valuesRef[0] === 10 ? 'none' : 'rgba(0, 0, 0, 0.1)',
  },
]

function formatLabelsForGraphs(context, datasetIndex) {
  const gameName = datasetIndex?.labels?.[context?.index]?.[0]
  const label = context?.label ?? ''
  const title = `${gameName} ${Number(label)?.toFixed(2)}`
  return context?.datasetIndex === 0 ? `${RADART_LABEL + title}%` : ''
}

export const options = {
  responsive: true,
  scale: {
    ticks: {
      display: false,
    },
    pointLabels: {
      fontColor: '#fff',
    },
  },
  layout: {
    padding: {
      left: 10,
      right: 10,
    },
  },
  legend: {
    display: false,
  },
  aspectRatio: 1,
  tooltips: {
    callbacks: {
      label(context, datasetIndex) {
        return formatLabelsForGraphs(context, datasetIndex)
      },
      title() {
        return ''
      },
      afterLabel() {
        return ''
      },
      value() {
        return ''
      },
      formatter() {
        return ''
      },
    },
    enabled: true,
  },
}

export const dataSetDonut = (values) => [
  {
    data: values,
    borderWidth: [2, 2, 2, 2],
    backgroundColor(context) {
      const { ctx } = context.chart
      const gradient = ctx.createLinearGradient(0, 0, context.chart.width, 0)
      values.forEach((value, index) => {
        gradient.addColorStop(value / 100, COLORS_DONUT_GRAPH[index].color)
      })

      return gradient
    },
    pointBorderColor: 'yellow',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(179,181,198,1)',
    borderColor: 'none',
    fontColor: '#fff',
    plugins: {
      legend: {
        labels: {
          fontColor: '#fff',
        },
      },
    },
  },
]

function getDonutLabel(context, datasetIndex) {
  const label =
    `${datasetIndex?.labels?.[context?.index]?.[0]} ${
      datasetIndex?.datasets[0]?.data?.[context?.index]
    }%` || ''
  return label
}

export const optionsDonut = {
  plugins: {
    legend: {
      labels: {
        fontColor: '#fff',
      },
    },
  },
  aspectRatio: 1,
  responsive: true,
  tooltips: {
    callbacks: {
      label(context, datasetIndex) {
        return getDonutLabel(context, datasetIndex)
      },
      title() {
        return ''
      },
    },
    enabled: true,
  },
}

// Notes constants
// Notes actions
export const NOTE_CLOSE = 'close'
export const NOTE_EDIT = 'edit'
export const NOTE_DELETE = 'deelte'
export const NOTE_EXPAND = 'expand'
export const NOTE_CONTRACT = 'contract'
export const NOTE_NONE = 'none'
export const NOTE_SAVE = 'save'
export const NOTE_ADD = 'add'
export const NOTE_COLLAPSE_ALL = 'contract_all'
export const NOTE_EXPAND_ALL = 'expand_all'
export const COLLAPSE = 'Collapse all'
export const EXPAND = 'Expand all'
export const ADD = 'Add note'
export const LAST_EDIT = 'Last Edit: '
export const USER = 'User: '
export const NOTES = 'notes'
export const TITLE = 'title'
export const TEXT_AREA_HEIGHT = 150
export const NOTE_EMPTY = {
  title: '',
  id: 0,
  notes: '',
  lastEdit: new Date().toISOString(),
  isCollapsed: true,
  isEditing: false,
  toDelete: false,
}

// Icons width
export const ICON_WIDTH = 20
export const ICON_MAINTENANCE_WIDTH = 100
export const ICON_HEADER_WIDTH = 100
export const NOTES_ITEMS_PER_PAGE = 10
export const NOTES_MAXIMUM_CONTENT = 5000
export const DELETE_NOTE_TITLE = 'Do you want to delete this note?'

// Self exclusion constant messages
export const CHANGE_STATUS_TO_SELF_EXCLUDE =
  'CHANGE PLAYER ACCOUNT TO SELF-EXCLUDED'
export const SELF_EXCLUDED_CHANGE_DURATION =
  'CHANGE PLAYER SELF-EXCLUDED DURATION'
export const TO_SELF_EXCLUDED_INDEFINITE =
  'Self-exclusion duration is indefinite.'

export const S1_M = 'duration'
export const S2_M = 'date'
export const S3_M = 'indefinite'
export const S1_LABEL = 'Self-exclude by duration'
export const S2_LABEL = 'Self-exclude by end date'
export const S3_LABEL = 'Self-exclude indefinitely'
export const SELF_EXLCUDED_IDS = [S1_M, S2_M, S3_M]
export const SELF_EXLCUDED_LABELS = [S1_LABEL, S2_LABEL, S3_LABEL]

export const SELF_EXLCUDED_IDS_2 = [S2_M, S3_M]
export const SELF_EXLCUDED_LABELS_2 = [S2_LABEL, S3_LABEL]
export const SELF_EXLCUDED_LABELS_3 = [S2_LABEL]

export const DURATION_OPTIONS = [
  { value: 1, label: '24 hours' },
  { value: 2, label: '48 hours' },
  { value: 7, label: '7 days' },
  { value: 14, label: '14 days' },
  { value: 30, label: '30 days' },
  { value: 60, label: '60 days' },
  { value: 90, label: '90 days' },
]

export const DURATION_LABEL = 'Duration'
export const TIME_LABEL = 'Time'
export const GROUP_EXCLUDE_OPTIONS = [
  'selfExcluded',
  'selfExcludedByEndDate',
  'selfExcludedIndefinite',
]
export const GROUP_MIN_EXCLUDE_OPTIONS = [
  'selfExcludedByEndDate',
  'selfExcludedIndefinite',
]
export const SELF_EXCLUDED_TITLE = 'CHANGE PLAYER SELF-EXCLUDED DURATION'
export const NO_SELF_EXCLUDED_TITLE =
  'CHANGE PLAYER PLAYER ACCOUNT TO SELF-EXCLUDED'

export const SELF_EXCLUDED = 'self-excluded'
export const UTC_LABEL = 'UTC'
export const DATE_OPTION = 'DATE'
export const INDEFINITE_OPTION = 'INDEFINITE'
export const TIME_OPTION = 'TIME'

// Financial constants
export const DEFAULT_WALLET = 'GBP'

// Export reports constants

// Keys to delete
export const TO_FORMAT_TOP_PLAYERS_WAGERS = {
  name: 'Name',
  last_login: 'Last Login',
  total_wagers: 'Total Wagers',
  xtremepush_url: 'xtremepush Link',
}
export const TO_FORMAT_TOP_PLAYERS_SESSION = {
  name: 'Name',
  last_login: 'Last Login',
  session_duration: 'Session Duration',
  xtreme_push_url: 'xtremepush Link',
}
export const TO_FORMAT_FINANCIAL = {
  player_id: 'GUID',
  transaction_date: 'Date Time UTC',
  id: 'Reference',
  type: 'Type',
  amount: 'Cash',
  bonus: 'Bonus',
  status: 'Status',
  balance: 'Balance',
}

export const TO_FORMAT_PLAYERS = {
  GUID: 'GUID',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  sign_up_date: 'Sign Up Date',
  xtremepush_url: 'Xtremepush Url',
}

// Titles files
export const TOP_PLAYERS_WAGERS = 'Top Players Wagers'
export const TOP_PLAYERS_SESSION = 'Top Players Sessions'
export const FINANCIAL = 'Financial'
export const PLAYERS = 'Players'
export const EXPORT_TITLE = 'Export to CSV'

// Engagement constants
export const SELECT_OPTIONS = ['Show', 'for the last']

export const PLAYER_TEST_ID_PROD = 'ab5f4d7d-fec5-11ec-b3c5-0242c0a8100c'
export const PLAYER_TEST_ID_DEV = '49aee128-232f-11ef-bc02-e2904b4c698a'

export const AUDIT_OPTION = NAVIGATION_MENU_OPTIONS[5]?.optionKey
export const NOTES_OPTION = NAVIGATION_MENU_OPTIONS[4]?.optionKey
export const MARQUETING_OPTION = NAVIGATION_MENU_OPTIONS[1]?.optionKey
