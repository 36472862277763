import axios from 'axios'
import store from '@redux/store'
import { logoutAction, setRefreshToken } from '@redux/actions/sessionActions'
import { getValidationResponse } from '@utils'

export const cleanup = () => {
  sessionStorage.removeItem('refreshToken')
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('user')
  store.dispatch(logoutAction())
  window.location.replace('/login')
}

export const AxiosRefreshClientHandlers = {
  handleRequest: (config) => {
    const refreshToken = sessionStorage.getItem('refreshToken')
    config.headers.Authorization = refreshToken ? `Bearer ${refreshToken}` : ''

    return config
  },
  handleResponse: async (err) => {
    if (err?.response?.status === 403 || err?.response?.status === 401)
      cleanup()
  },
}

export const AxiosAccessClientHandlers = {
  handleRequest: (config) => {
    const token = sessionStorage.getItem('token')
    config.headers.Authorization = token ? `Bearer ${token}` : ''

    return config
  },
  handleResponse: async (err, axiosClient, axiosClientRefresh) => {
    const token = sessionStorage.getItem('token')
    const originalConfig = err.config
    const method = err?.response?.config?.method
    if (err?.response?.status !== 403 && err?.response?.status !== 401)
      if (getValidationResponse(method)) window.location.replace('/maintenance')

    if (err?.response?.status === 403) cleanup()

    if (err?.response?.status === 401) {
      if (!token) {
        cleanup()
        return () => Promise.reject(err)
      }

      try {
        const res = await axiosClientRefresh.post('/admins/refresh')
        sessionStorage.setItem('token', res.data.result.token)
        sessionStorage.setItem('time', new Date())
        axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`
        store.dispatch(setRefreshToken(res.data.result))
        return axiosClient.request(originalConfig)
      } catch (e) {
        store.dispatch(setRefreshToken(false))
      }
    }

    return () => {
      Promise.reject(err)
    }
  },
}
