import { QueryClient } from 'react-query'

import WebSocket from './websocket/WebSocket'
import { useAdmins } from './admins'
import { useExitPages } from './analytics'
import { usePlayerBalances } from './balances'
import {
  useBonuses,
  useBonus,
  useNewBonusMutation,
  useUserBonuses,
} from './bonuses'
import {
  useCampaigns,
  useCampaign,
  useCampaignEnabledMutation,
  useNewCampaignMutation,
} from './campaigns'
import { useCurrencies } from './currencies'
import { useTopGames } from './games'
import {
  usePlayer,
  usePlayers,
  usePlayerChangelog,
  usePlayersChangelog,
  usePlayersOnline,
  usePlayersStatusDurationMutation,
  usePlayersSession,
  usePlayerAnalytics,
  usePlayersStatusMutation,
  usePlayerOnline,
  usePlayersStatus,
  usePlayersStatusId,
  useTotalPlayers,
  useTotalNewPlayers,
  useActivePlayers,
  useActiveTrends,
  useNewTrends,
  useTotalTrends,
  useTotalByCountry,
  useTopByCountry,
  useTotalContinent,
  useTotalNewContinent,
  useStats,
  useUpdatePlayerMutation,
  useNewNotePlayerMutation,
  useNotesPlayer,
  useNotePlayer,
  useDeleteNoteMutation,
  useEditNoteMutation,
  useSelfExclusionInformation,
} from './players'
import { useProviders } from './providers'
import { useUserTotalDeposits } from './total-deposits'
import {
  useNewTransactionLimitMutation,
  useTransactionLimit,
  useEditTransactionLimitMutation,
  useDeleteTransactionLimitMutation,
} from './transaction-limit'
import {
  useAddBonusTransactionMutation,
  useUserTransactions,
  useTransactions,
  useTopTransactions,
  useTransactionsTypes,
  useTopPlayers,
} from './transactions'

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
})

export {
  useAdmins,
  useExitPages,
  usePlayerBalances,
  useBonuses,
  useBonus,
  useNewBonusMutation,
  useUserBonuses,
  useCampaigns,
  useCampaign,
  useCampaignEnabledMutation,
  useNewCampaignMutation,
  useCurrencies,
  useTopGames,
  usePlayer,
  usePlayers,
  usePlayerChangelog,
  usePlayersChangelog,
  usePlayersOnline,
  usePlayersStatusDurationMutation,
  usePlayersSession,
  usePlayerAnalytics,
  usePlayersStatusMutation,
  usePlayerOnline,
  usePlayersStatus,
  usePlayersStatusId,
  useTotalPlayers,
  useTotalNewPlayers,
  useActivePlayers,
  useActiveTrends,
  useNewTrends,
  useTotalTrends,
  useTotalByCountry,
  useTopByCountry,
  useTotalContinent,
  useTotalNewContinent,
  useStats,
  useUpdatePlayerMutation,
  useNewNotePlayerMutation,
  useNotesPlayer,
  useNotePlayer,
  useDeleteNoteMutation,
  useEditNoteMutation,
  useSelfExclusionInformation,
  useProviders,
  useUserTotalDeposits,
  useNewTransactionLimitMutation,
  useTransactionLimit,
  useEditTransactionLimitMutation,
  useDeleteTransactionLimitMutation,
  useAddBonusTransactionMutation,
  useUserTransactions,
  useTransactions,
  useTopTransactions,
  useTransactionsTypes,
  useTopPlayers,
  WebSocket,
}
