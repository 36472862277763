import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import useAxiosClientAuth from '@hooks/clientAuthHook'
import Loading from '@components/atoms/Generic/Loading'
import {
  getSessionToken,
  getSessionUser,
  getUIDarkMode,
} from '@redux/selectors'
import ProtectedRoute from '@components/organisms/ProtectedRoute'
import './styles/style.scss'

// Containers
const TheLayout = React.lazy(() => import('@layouts'))
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function Fallback(props) {
  return <Login {...props} />
}

function ProtectedLayout(props) {
  return <TheLayout {...props} />
}

function App() {
  // --- Local state -----------------------------------------------------------
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)',
  ).matches
  const [isLoading, setIsLoading] = useState(true)
  // --- END: Local state ------------------------------------------------------
  // --- Hooks -----------------------------------------------------------------
  useAxiosClientAuth()
  // --- END: Hooks ------------------------------------------------------------
  // --- Redux -----------------------------------------------------------------
  const dispatch = useDispatch()
  const currentUser = useSelector(getSessionUser)
  const token = useSelector(getSessionToken)
  const darkMode = useSelector(getUIDarkMode)
  // --- END: Redux ------------------------------------------------------------
  // --- Side effects ----------------------------------------------------------
  useEffect(() => {
    const script = document.createElement('script')
    script.src = `${
      window._env_.REACT_APP_LIVE_CHAT_URL
    }/widgetApi.js?timestamp=${new Date().getTime()}`
    document.head.appendChild(script)
    script.onload = () => {
      setIsLoading(false)
    }

    return () => {
      // Clean up the script element
      document.head.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (darkMode === null) {
      if (prefersDarkMode) dispatch({ type: 'set', darkMode: true })
      else dispatch({ type: 'set', darkMode: true })
    }
  }, [darkMode, dispatch, prefersDarkMode])
  // --- END: Side effects -----------------------------------------------------
  // --- Data and handlers -----------------------------------------------------
  // --- END: Data and handlers ------------------------------------------------
  return (
    !isLoading && (
      <>
        <ReactNotification />
        <Router>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <ProtectedRoute
                path="/"
                auth={Boolean(currentUser && token)}
                fallback={Fallback}
                name="Dashboard"
                component={ProtectedLayout}
              />
            </Switch>
          </React.Suspense>
        </Router>
      </>
    )
  )
}

export default App
