import { GENERIC_ERROR_NOTIFICATION } from '@constants'

export const ERROR_NOTIFICATION = {
  400: {
    ...GENERIC_ERROR_NOTIFICATION,
    message: 'Bad request',
  },
  401: {
    ...GENERIC_ERROR_NOTIFICATION,
    message: 'Email or password do not match.',
  },
  403: {
    ...GENERIC_ERROR_NOTIFICATION,
    message: 'The user is not allowed to login.',
  },
  404: {
    ...GENERIC_ERROR_NOTIFICATION,
    message: 'Not found',
  },
  500: {
    ...GENERIC_ERROR_NOTIFICATION,
    message: 'Internal server error',
  },
}

export const URL_DEFAULT = `https://dev-mikado-backoffice-api.ptops.net/api/v1/`
