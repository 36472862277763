import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { updateBalance } from '@redux/actions/playerDetailsActions'
import { getSessionUser } from '@redux/selectors'

const WebSocketContext = createContext(null)

export { WebSocketContext }

function Socket({ children }) {
  let socket
  let ws

  const dispatch = useDispatch()
  const usr = useSelector(getSessionUser)

  if (usr === null) {
    ws = {}

    return (
      <WebSocketContext.Provider value={ws}>
        {children}
      </WebSocketContext.Provider>
    )
  }

  const { id } = usr

  if (!socket) {
    const baseUrl = window._env_.REACT_APP_WEBSOCKET_URL
    socket = new WebSocket(`${baseUrl}/ws?id=${id}`)
    socket.addEventListener('message', (e) => {
      const payload = JSON.parse(e.data)
      dispatch(updateBalance(payload))
    })

    ws = {
      socket,
    }
  }

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  )
}

Socket.propTypes = {
  children: PropTypes.element,
}

Socket.defaultProps = {
  children: null,
}

export default Socket
