import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import './polyfill'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import store from '@redux/store'
import { queryClient, WebSocket as WebSocketProvider } from '@services'
import { icons } from './assets/icons'
import * as serviceWorker from './serviceWorker'
import App from './App'

React.icons = icons

const persistor = persistStore(store)
const root = createRoot(
  document.getElementById('root') || document.createElement('root'),
)

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <WebSocketProvider>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </WebSocketProvider>
    </PersistGate>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
