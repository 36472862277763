import React from 'react'
import PropTypes from 'prop-types'
import { CContainer } from '@coreui/react'
import classNames from 'classnames'

function PlayerCard({ children, title, noPadding }) {
  return (
    <CContainer
      test-id="player-Card"
      className={classNames({
        'c-player-card': true,
        'p-0 rounded-pill': noPadding,
      })}
      fluid
    >
      {title && (
        <p className="c-player-card-text">
          <strong>{title}</strong>
        </p>
      )}
      {children}
    </CContainer>
  )
}

PlayerCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  noPadding: PropTypes.bool,
}

PlayerCard.defaultProps = {
  children: null,
  title: '',
  noPadding: false,
}

export default PlayerCard
